import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CidToCnamePipe } from './cid-to-cname.pipe';
import { MomentDatePipe } from './moment-date.pipe';
import { MonthNamePipe } from './month-name.pipe';
import { SafePipe } from './safe.pipe';
import { TruncatePipe } from './truncate.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [SafePipe, CidToCnamePipe, MonthNamePipe, MomentDatePipe, TruncatePipe],
    providers: [],
    exports: [SafePipe, CidToCnamePipe, MonthNamePipe, MomentDatePipe, TruncatePipe],
})
export class PipesModule {}
